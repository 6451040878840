
import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Translation } from 'react-i18next';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Link} from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import '../../../src/assets/Slider.css';



class Slider extends Component {
    render() {
        return (
    <div>
      <article className="carousel-holder">
        <Carousel>
          {/* First slide */}
          <Carousel.Item>
            <section className="carousel-frame">
              <img src="./assets/img/banner1.jpg" alt="First slide" width={'100%'} />
              <Carousel.Caption>
              <h2><Translation>{(t) => <span>{t("slider_title")}</span>}</Translation></h2> 
              <Link to={'/new-lead'} className="red"><Translation>{(t) => <span>{t("slider_button")}</span>}</Translation></Link>

              <p><Translation>{(t) => <span>{t("slider_text")}</span>}</Translation></p>

              </Carousel.Caption>
            </section>
          </Carousel.Item>
          {/* Second slide */}
          <Carousel.Item>
            <section className="carousel-frame">
              <img src="./assets/img/banner2.jpg" alt="second slide" width={'100%'} />
              <Carousel.Caption>
              <h2><Translation>{(t) => <span>{t("slider_title")}</span>}</Translation></h2> 
              <Link to={'/new-lead'} className="red"><Translation>{(t) => <span>{t("slider_button")}</span>}</Translation></Link>
              <p><Translation>{(t) => <span>{t("slider_text")}</span>}</Translation></p>

              </Carousel.Caption>
            </section>
          </Carousel.Item>
          {/* Third slide */}
          <Carousel.Item>
            <section className="carousel-frame">
              <img src="./assets/img/banner3.jpg" alt="Third slide" width={'100%'} />
              <Carousel.Caption>
              <h2><Translation>{(t) => <span>{t("slider_title")}</span>}</Translation></h2> 
              <Link to={'/new-lead'} className="red"><Translation>{(t) => <span>{t("slider_button")}</span>}</Translation></Link>
              <p><Translation>{(t) => <span>{t("slider_text")}</span>}</Translation></p>

              </Carousel.Caption>
            </section>
          </Carousel.Item>
        </Carousel>
        </article>
       {/* <Carousel infiniteLoop autoPlay>
          <div className='image'>
          <img src="./assets/img/banner1.webp" alt="mobile" />
          </div>
          <div className='image'>
            <img src="./assets/img/banner1.webp" alt="mobile" />
          </div>
          <div className='image'>
            <img src="./assets/img/banner1.webp" alt="mobile" />
          </div>
          <div className='image'>
            <img src="./assets/img/banner1.webp" alt="mobile" />
          </div>
      </Carousel> */}

             {/* <section id="cta" className="cta">
                  <div className="container" data-aos="zoom-in">
                    <div className="row">
                      <div className="col-lg-9 text-center text-lg-start">
                        <h1><Translation>{(t) => <span>{t("slider_title")}</span>}</Translation></h1>
                        <br />
                        <h3><Translation>{(t) => <span>{t("slider_subtitle")}</span>}</Translation></h3>
                        <p> <Translation>{(t) => <span>{t("slider_text")}</span>}</Translation></p>
                        <a className="cta-btn align-middle" href={'/new-lead'}><Translation>{(t) => <span>{t("slider_button")}</span>}</Translation></a>
                      </div>
                    </div>
                  </div>
                </section> */}

    </div>
  )
}
}
export default  Slider;