
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Translation } from 'react-i18next';
import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";



i18n
.use(LanguageDetector)
.use(initReactI18next)
.use(HttpApi)
.init({
  supportedLngs: ['EN', 'FR'],
  fallbackLng: 'FR',
  backend: {
    loadPath: './assets/locales/{{lng}}/translation.json',
  },
  react: {useSuspense: true,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i"],},
});


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'FR' ? 'EN' : 'FR'
    };
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  handleLanguageChange() {
    this.setState({
      language: this.state.language === 'FR' ? 'EN' : 'FR'
    });
    i18n.changeLanguage(this.state.language);
  }



    render() {
      const { t } = this.props;


        return (
    <div>
  
    <Navbar bg="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="/">
          <img
            src='assets/img/colored.png'
            width="120px"
            className="logo me-auto navbarStyle"
            alt="ALTER CREDIT!"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          </Nav>
             <Nav.Link href="/">
               <Translation>{(t) => <span>{t("navbar_title1")}</span>}</Translation>
            </Nav.Link>
            <Nav.Link className='is-active' href="/#about">
               <Translation>{(t) => <span>{t("navbar_title2")}</span>}</Translation>
            </Nav.Link>
            <Nav.Link href="/#faq">
              <Translation>{(t) => <span>{t("navbar_title3")}</span>}</Translation>
            </Nav.Link>
            <Nav.Link>
              <span onClick={this.handleLanguageChange}>{this.state.language}</span>
            </Nav.Link>
          <Link to={'/new-lead'} className="getstarted scrollto me-2"><Translation>{(t) => <span>{t("navbar_button")}</span>}</Translation></Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  )
}
}
export default  Header;